
<template>
  <li class="comment-card">
    <div class="comment-header">
      <div class="avatar">
        <img :src="avatar()" :alt="$t('comment.poster_avatar')" @error="avatarImageError">
      </div>
      <div class="profile-detail">
        <div class="profile-line1">
          <div class="poster-name"><strong>{{ note.author ? note.author : $t('comment.unknown_author') }}</strong></div>
          <div class="comment-actions" v-if="!readOnly">
            <template v-if="canEdit()">
              <b-button :id="`BTN_EDIT_${id}`" class="comment-action" size="sm" @click="editNote">
                <font-awesome-icon :icon="['far', 'pen']"/>
              </b-button>
              <b-popover :target="`BTN_EDIT_${id}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('button.edit') }}
              </b-popover>
            </template>
            <template v-if="canDelete()">
              <b-button :id="`BTN_DELETE_${id}`" class="comment-action" size="sm" @click="deleteNote">
                <font-awesome-icon :icon="['far', 'trash-can']"/>
              </b-button>
              <b-popover :target="`BTN_DELETE_${id}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('button.delete') }}
              </b-popover>
            </template>
          </div>
        </div>
        <div class="profile-line2">{{ getDateString() }}</div>
      </div>
    </div>
    <div class="note markdown-body" :ref="id" :id="id" :class="{ 'note-max-height': !allowReadMore }">
      <div v-html="compiledMarkdown" />
      <div v-if="readMoreShow" class="read-more">
        <div @click="readMoreClick"><strong>{{ $t('comment.link.read_more') }}</strong></div>
      </div>
    </div>
  </li>
    
</template>

<script>
import { strRandom } from '@/helpers';
import * as DOMPurify from 'dompurify';
import * as Marked from 'marked';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);

const renderer = new Marked.Renderer();
renderer.link = function(href, title, text) {
  const link = Marked.Renderer.prototype.link.call(this, href, title, text);
  return link.replace("<a","<a target='_blank' ");
};
Marked.setOptions({
  renderer: renderer,
  gfm: true
});

export default {
  name: 'Note',
  components: { 
    
  },
  props: {
    note:      { type: Object },
    authorMap:    { type: Array },
    requestState: { type: String },
    readOnly: { type: Boolean, default: false }
  },
  data() {
    return {
      permissionName: 'NOTE',
      noteShow: false,
      expand: false,
      readMoreShow: false,
      allowReadMore: false,
      baseUrl: process.env.BASE_URL
    };
  },
  mounted() {
    this.updateReadMoreToggleState();
    this.addLinkTargets();
  },
  updated() {
    this.addLinkTargets();
  },
  created() {
    this.id = `COMMENT_${strRandom(5)}`;
  },
  beforeDestroy() {
    this.id = null;
  },
  watch: {
    authorMap: {
      handler: function() {
        this.avatar(); // update the avatar
      },
      deep: true
    },
    requestState: function(val) {
      if (val.indexOf(this.note.authorRef) !== -1) {
        this.$forceUpdate();
      }
    },
    note: function() {
      this.allowReadMore = false; //Reset readMore state
      this.updateReadMoreToggleState();
    },
  },
  computed: {
    compiledMarkdown: function () {
      return DOMPurify.sanitize(Marked(this.note.text));
    },
    defaultAvatarUrl() {
      return `${this.baseUrl}img/avatars/male.png`;
    }
  },
  methods: {
    async updateReadMoreToggleState() {
      let loop = true;
      // avoid an infinite loop when the component is hidden
      if (this.$refs[this.id].clientHeight !== 0) {
        while(loop) {
          await this.$nextTick();
          if(this.$refs[this.id].clientHeight > 0 && this.$refs[this.id].offsetHeight > 0 && this.$refs[this.id].scrollHeight) {
            loop = false;
          }
        }
      }
      if(this.isNoteOverflowed()) {
        this.readMoreShow = true;
      } else {
        this.readMoreShow = false;
      }
    },
    async addLinkTargets() {
      // Find links in the note and add target=_blank to open in new tab
      Array.prototype.forEach.call(document.getElementById(this.id).getElementsByTagName('a'), a => {
        a.setAttribute('target', '_blank');
      })
    },
    avatar() {
      if (!this.canView('STORAGE_FILE')) {
        return `${process.env.BASE_URL}img/avatars/male.png`;
      }
      const author = this.authorMap.filter(a => a.uuId === this.note.authorRef);
      if (author.length !== 0 && author[0].avatarRef != '00000000-0000-0000-0000-000000000000') {
        return `/api/file/${author[0].avatarRef}`;
      }
      return `${process.env.BASE_URL}img/avatars/male.png`;
    },
    getDateString() {
      if(moment.locale() != this.$i18n.locale) {
        moment.locale(this.$i18n.locale);
      }
      return moment(this.note.modified).format('DD MMM YYYY HH:mm');
    },
    editNote() {
      this.$emit('edit', this.note.uuId);
    },
    deleteNote() {
      this.$emit('toRemove', this.note.uuId);
    },
    isNoteOverflowed() {
      const el = this.$refs[this.id];
      return el.scrollHeight > el.offsetHeight;
    },
    readMoreClick() {
      this.readMoreShow = false;
      this.allowReadMore = true;
    },
    avatarImageError(event) {
      if (event.target.src != this.defaultAvatarUrl) {
        event.target.src = this.defaultAvatarUrl;
      }
    }
  }
}
</script>
<style lang="scss">
.comment-card {
  margin: 5px;
  border: 1px solid var(--form-control-border);
  padding: 10px;
  position: relative;
  background-color: var(--comment-bg);

  .comment-header {
    display: flex;
  }
  .avatar {
    flex: 0 0 40px;
    height: 40px;
  }
  .avatar > img {
    width: 36px;
    height: 36px;
  }
  .profile-detail {
    flex: 1 1 auto;
    flex-direction: column;
    margin-left: 8px;
  }
  .profile-line1 {
    display: flex;
  }
  .profile-line2 {
    font-size: 0.75rem;
  }

  .comment-actions {
    margin-left: auto;
  }
  .comment-action {
    color: var(--text-light);
    padding-top:2px;
    padding-bottom:2px;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 4px;
    margin-right: 2px;
    
    &, &[disabled]:active,
    &.btn-secondary,&.btn-secondary[disabled]:active {
      background-color: transparent;
      border: 0;
    }
    &:focus {
      box-shadow: none;
    }
    &:hover {
      color: var(--text-dark);
    }
  }
  .note {
    position: relative;
    font-size: 0.8rem;
    font-family: Arial;
    overflow: hidden;
    & p {
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }
  .note-max-height {
    max-height: 80px; 
  }

  .read-more {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 38px;
    width: 100%;
    background: linear-gradient(to top, var(--comment-readmore-gradient-from) 0%, var(--comment-readmore-gradient-from) 20%, var(--comment-readmore-gradient-to) 100%);
    text-align: center;
    vertical-align: bottom;
    div {
      margin-top: 10px;
      padding-top: 3px;
      padding-bottom: 3px;
      padding-left: 12px;
      padding-right: 12px;
      display: inline-block;
      cursor: pointer;
      border-radius: 5px;
      color: var(--text-medium);
      background-color: var(--surface-bg);
    }
    div:hover {
      text-decoration: underline;
    }
  }
}

</style>